<template>
  <ion-row>
    <ion-col>
      <ion-card>
        <ion-card-header>
          <ion-row>
            <ion-col>
              <ion-card-title>
                Orden de Producción {{ $filters.folio(product.id_production) }}
              </ion-card-title>
              <ion-card-subtitle>
                <p class="subtitle mt-1">
                  Fecha de Solicitud: {{product.status}}
                  <span class="ion-primary-color">
                    {{ $filters.timeFrom(product.date) }}</span
                  >
                </p>
              </ion-card-subtitle>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <span
                class="status"
                :class="{
                  solicitado: product.revision == 0,
                  produccion:
                    product.revision == 1 && product.status != 'desechado',
                  danger: product.status == 'desechado',
                }"
                >{{
                  $filters.capitalize(
                    product.status == "desechado"
                      ? "Desechado"
                      : product.revision == "0"
                      ? "revision"
                      : "Revisado"
                  )
                }}</span
              >
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="tab-head">
            <ion-col size="6"> </ion-col>
            <ion-col size="6">
              <p>
                Fecha:
                <span class="ion-primary-color pull-right">
                  {{ moment(product.d_date).format("DD MMM YYYY") }}
                </span>
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="tab-row">
            <ion-col size="6">
              <ion-row class="ion-no-padding">
                <ion-col size="2">
                  <p>
                    <span class="number">
                      1
                    </span>
                  </p>
                </ion-col>

                <ion-col size="10">
                  <p v-if="product.name" class="tab-row-sub">
                    {{
                      product.name
                        .replace(product.capacity, "")
                        .replace("Lts.", "")
                    }}
                  </p>
                </ion-col>
                <ion-col size="12">
                  <p class="subtitle2 margin-left-col2">
                    {{
                      product.label
                        ? "#" + product.label
                        : $filters.folio(product.id)
                    }}
                    <span class="pull-right">
                      {{ product.segunda == 1 ? "Segunda" : "Primera" }}
                    </span>
                  </p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="2">
              <p class="p4 tab-row-sub">{{ product.capacity }} Lts.</p>
            </ion-col>
            <ion-col size="4">
              <ion-button
                type="button"
                class="ion-margin-bottom btn-edit"
                expand="block"
                color="outlined"
                @click="select(product.id)"
              >
                <ion-icon :icon="create"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</template>

<script>
import { useRouter } from "vue-router";
import moment from "moment-business-days";
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { create } from "ionicons/icons";
import { toRefs } from "@vue/reactivity";
export default {
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    IonIcon,
  },
  props: {
    product: Object,
  },
  setup(props) {
    const router = useRouter();
    const { product } = toRefs(props);
    function select(id) {
      router.push("/calidad/product/" + id + "/");
    }
    return { moment, create, select };
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
.btn-edit::part(native) {
  background: #fff;
  box-shadow: none;
  border: 1px solid var(--ion-color-medium-tint);
  color: var(--ion-color-medium-tint);
  padding: 20px 10px;
  transform: translateY(1px);
  width: auto;
  float: right;
}
</style>
